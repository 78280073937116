<template>
  <div>
    <wrapper-preview-category customClass="section-preview_lk">
      <grid-table
        :data-rows="founders"
        :headers="tableHeaders.foundersMassive"
        :headers-for-rows="tableHeaders.foundersMassive"
        :loading-data="tableData.loading"
        :num-cols="tableHeaders.countCells"
        max-table-height="700px"
      >
        <template #founderStatus="{ row }">
          <div>{{ foivStatus(row) }}</div>
        </template>
        <template #actions="{ row }">
          <button
            v-if="row.founderStatus.founderWorkflow === 'SENT' || row.founderStatus.founderWorkflow === 'BLOCKED'" class="button"
            style="margin: 0; padding: 2px 5px" @click="revokeByFounderId(row.founderId)">
            Разблокировать
          </button>
        </template>
      </grid-table>
    </wrapper-preview-category>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import request from "@/services/request";
import WrapperPreviewCategory from "@/components/GridTable/dataFolder/WrapperPreviewCategory";
import AdminQuotaTableHeaders from "@/components/GridTable/dataFolder/AdminQuotaTableHeaders";
import GridTable from "@/components/GridTable/GridTable";

export default {
  name: "AdminQuotaFounders",
  components: {GridTable, WrapperPreviewCategory},
  data: () => ({
    tableHeaders: [],
    val: 0,
    dialog: false,
    tableData: {
      loading: false,
      change: {value: false, id: null},
      error: null,
      editRow: null,
      data: [],
    },
    allData: [],
    founders: [],
  }),
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
  },
  
  created() {
    this.getAllForMon();
    this.tableHeaders = AdminQuotaTableHeaders(this);
  },
  methods: {
    async getAllForMon() {
      try {
        const res = await request({
          endpoint: `/api/spr/founder/getAllForMon`,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data) {
          this.founders = res.data;
          console.log('getAllForMon успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    async revokeByFounderId(arg) {
      try {
        const res = await request({
          endpoint: `/api/moduledatagather/revoke?founderId=${arg}`,
          method: 'get',
          token: `${this.getAuthData.token}`,
        });
        if (res.status === 200 && res.data) {
          console.log('revokeByFounderId успех');
          await this.getAllForMon();
          this.dialog = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    
    foivStatus(foiv) {
      if (foiv.founderStatus.founderWorkflow === 'WORKS') {
        return 'Работает'
      } else if (foiv.founderStatus.founderWorkflow === 'SENT') {
        return 'Отправлен на согласование'
      } else if (foiv.founderStatus.founderWorkflow === 'BLOCKED') {
        return 'Заблокирован'
      } else if (foiv.founderStatus.founderWorkflow === 'ACCEPTED_MON') {
        return 'Согласовано МОНом'
      } else if (foiv.founderStatus.founderWorkflow === 'ACCEPTED_GOV') {
        return 'Согласовано Правительством'
      }
    }
  }
}
</script>

<style lang="sass">
.yellow-background
  background-color: #fff3cd
  color: #D4990A

.section-preview__header
  font-size: 24px
  font-weight: bold
  margin: 16px 0
  color: #334D6E

.dialog-btn
  .v-btn__content
    padding: 10px 5px

.v-autocomplete
  padding: 0 24px

.v-text-field
  padding: 0 24px
</style>