// eslint-disable-next-line no-unused-vars
const AdminQuotaTableHeaders = (_this) => {
  return {
    countCells: 16,
    massive: [
      {
        dataField: 'sprOrgName',
        cols: 1,
        rows: 1,
        text: 'Образовательная организация',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        columnFilter: true,
        headerStyles: 'min-width: 150px'
        // template: (row) => {
        //   return row['sprOrgName'];
        // }
      },
      {
        dataField: 'currentOrgFounderName',
        cols: 1,
        rows: 1,
        text: 'Учредитель ВУЗа',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'sprSpecName',
        cols: 1,
        rows: 1,
        text: 'Направление обучения',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        columnFilter: true,
      },
      {
        dataField: 'kcpCoValue',
        cols: 1,
        rows: 1,
        text: 'КЦП',
        class: 'text-center',
        headerClass: 'text-center',
        sortable: true,
        typeCell: 'string',
        variable: true,
      },
      {
        dataField: 'numPlaces',
        cols: 1,
        rows: 1,
        text: 'Квота приёма на ЦО в выбранном вузе по данному направлению',
        class: 'text-center',
        headerClass: 'text-center',
        sortable: true,
        typeCell: 'string',
        variable: true,
      },
      {
        dataField: 'totalRequestAmountSum',
        cols: 1,
        rows: 1,
        text: 'Объём суммарной заявки респондентов',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        columnFilter: true,
      },
      {
        dataField: 'totalRequestAmount',
        cols: 1,
        rows: 1,
        text: 'Сумма мест детализированных для целевого приема',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        columnFilter: true,
      },
      {
        dataField: 'sprOrgFounderName',
        cols: 2,
        rows: 1,
        text: 'Респондент',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerStyles: 'min-width: 100px',
        headerClass: 'text-center',
        columnFilter: true,
      },
      {
        dataField: 'kcpVal',
        cols: 1,
        rows: 1,
        text: 'Количество мест детализированных для целевого приема',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'client',
        cols: 3,
        rows: 1,
        text: 'Заказчик',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-left',
        headerClass: 'text-center',
        headerStyles: 'min-width: 250px',
        template: (row) => row.item ? row.item.zakazchik : ''
      },
      {
        dataField: 'organization',
        cols: 1,
        rows: 1,
        text: 'Организация трудоустройства (возможно не заполнено)',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        headerStyles: 'min-width: 100px',
        template: (row) => row.item ? row.item.orgTrud : ''
      },
      {
        dataField: 'subject',
        cols: 1,
        rows: 1,
        text: 'Субъект трудоустройства',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'actions',
        cols: 1,
        rows: 1,
        text: '',
        variable: true,
      },
    ].map((el) => ({
      ...el,
      class: el.class + ' pa-0',
      styles: (row) => {
        if (['totalRequestAmount', 'sprOrgFounderName', ].includes(el.dataField))
          return !row.mainRow ? 'display: none!important;' : ''/*text-align: center; */
        if (['sprOrgName','kcpCoValue','currentOrgFounderName', 'sprSpecName', 'totalRequestAmountSum', 'numPlaces', ].includes(el.dataField))
          return !row.mainRow2 ? 'display: none!important;' : ''/*text-align: center; */
        return ''
      },
      rowsDataCalc: (row) => {
        const len = row.array?.length
        if (['totalRequestAmount', 'sprOrgFounderName', ].includes(el.dataField))
          return row.mainRow ? (len === 0 ? 1 : len ) : 1
        const len2 = row?.countRas
        if (['sprOrgName','kcpCoValue','currentOrgFounderName', 'sprSpecName', 'totalRequestAmountSum', 'numPlaces', ].includes(el.dataField))
          return row.mainRow2 ? (len2 === 0 ? 1 : len2  ) : 1
        return 1
      },
    })),
    organizationsMassive: [
      {
        dataField: 'sprOrgName',
        cols: 8,
        rows: 1,
        text: 'Организация',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        // template: (row) => {
        //   return row['sprOrgName'];
        // }
      },
      {
        dataField: 'lookedUpAfterMon',
        cols: 4,
        rows: 1,
        text: 'Просмотрено после согласования МОН',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'lookedUpAfterGov',
        cols: 2,
        rows: 1,
        text: 'Просмотрено после согласования Правительством',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'downloaded',
        cols: 1,
        rows: 1,
        text: 'Скачано',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
    ],
    foundersMassive: [
      {
        dataField: 'orgFounderName',
        cols: 9,
        rows: 1,
        text: 'Название ФОИВа',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'founderStatus',
        cols: 4,
        rows: 1,
        text: 'Статус ФОИВа',
        sortable: true,
        typeCell: 'string',
        variable: true,
      },
      {
        dataField: 'actions',
        cols: 2,
        rows: 1,
        text: '',
        variable: true,
      },
    ],
    regionsMassive: [
      {
        dataField: 'regionName',
        cols: 9,
        rows: 1,
        text: 'Название региона',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'founderStatusRegion',
        cols: 4,
        rows: 1,
        text: 'Статус региона',
        sortable: true,
        typeCell: 'string',
        variable: true,
      },
      {
        dataField: 'actions',
        cols: 2,
        rows: 1,
        text: '',
        variable: true,
      },
    ],
  };
}
export default AdminQuotaTableHeaders
